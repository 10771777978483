import React, {useEffect, useState} from "react"
import { connect } from "react-redux"
import { httpApiGateway } from "../adapters/secondary/httpEshopApi";
import { Order, StaticContext } from "../domain/interfaces";
import Page from "../templates/page"
import { navigate } from 'gatsby'
import { ShopState } from "../state/state.interface";
import { Svg } from "../components/svgIcons"
import { removeProductFromBasket } from "./../usecases/basket"

const ApiGateway = new httpApiGateway()

const connector = connect((state: ShopState) => ({
  state
}))

const aAccount = ({state, dispatch}) =>  {

  const api = new httpApiGateway()
  const [context, setContext] = useState<StaticContext | null>(null)
  const [logged, setLogged] = useState<boolean>(false)
  const [orders, setOrders] = useState<Order[]>([])

  useEffect(() => {
    api.retrieveStaticContext().then(setContext)
    api.imLogged().then(islogged => {
      if (!islogged) navigate('/signin')
      setLogged(islogged)
      api.getOrders().then(orders => setOrders(orders))
    })
  }, []) // given empty array useEffect run only once


  return logged && <Page pageContext={context}>
    <div className="mt-10 flex flex-col items-center min-h-96 mb-20">

      <div className="mt-10">
          <h1 className="text-brand-primary text-2xl font-bold w-full text-center"> Votre panier </h1>
          <div className="mt-5 w-full">
              { state.basket.loading &&  <p className="text-sm text-gray-500"> chargement du panier </p> }
              { state.basket.products.length == 0 && state.basket.loading == false && <p className="text-sm text-gray-500">
                  Votre Panier est vide pour le moment
              </p> }
              { state.basket.products.length > 0 && <>
              { state.basket.products.map((product: any) => 
                  <div className="mb-4 w-full flex flex-row" key={product.product.id}> 
                      <img className="h-20 w-20 rounded-sm" src={product.product.coverImage.thumbnail.imgUrl} />
                      <div className="ml-2 w-full"> 
                          <h1 className="text-xl -mb-1 -mt-1"> {product.quantity} {product.product.name} </h1> 
                          <div className="text-xs text-gray-500"> {product.product.reference} </div>
                          <div className="mt-1 text-xs"> {product.product.priceWithTaxFormat} {product.product.currencySymbol} </div>
                          {product.product.stockAvailable <= 0 && <div className="text-xs text-red-500 font-bold uppercase"> Cet article n'est plus disponible ! </div> }
                          <button onClick={()=>dispatch(removeProductFromBasket(product.product.id))} className="focus:outline-none cursor-pointer float-right h-4 w-4 text-gray-800 hover:text-brand-primary"> <Svg icon="trash"></Svg> </button> 
                      </div>
                  </div>
              )}
              {!!state.basket.priceTotalDeliveryFormated && (<span> Livraison {state.basket.priceTotalDeliveryFormated} </span>)} <br/>
              {!!state.basket.priceTotalFormated && (<span> Total {state.basket.priceTotalFormated} </span>)}
              </> }
          </div>
          {state.basket.outOfStock == true && <div className="mb-4 flex m-1 font-medium py-1 px-2 bg-white rounded-md text-red-700 bg-red-100 border border-red-300 ">
              <div className="text-md font-normal max-w-full flex-initial">
                  Certains articles de votre panier ne sont plus disponibles, merci de les retirer.
              </div>
          </div> }
          {state.basket.outOfStock == false && <div className="w-full text-center mt-5 mb-20">
            { state.basket.products.length > 0 && <a href="/checkout/" type="button" className="mx-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-primary-lighter text-base font-medium text-white hover:bg-brand-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
              Passer ma commande
            </a> }
          </div> }
      </div>

      <h1 className="text-brand-primary text-2xl font-bold"> Vos commandes </h1>
      { orders.length == 0 && <> 
          <p className="w-96 text-gray-500 mt-5 text-center">
            Vos commandes apparaissent ici.
            Vous n'avez pas encore passer commande chez nous. <br/>
          </p> 
          <div className="mt-10 mb-20">
              <a href="/" className="bg-brand-primary hover:bg-brand-primary-darkest text-white font-bold py-2 px-4 rounded" type="a">
                      Retourner à la boutique
              </a>
          </div>
      </>
      }
      { orders.length > 0 && <div className="w-full">
      <table className="table-auto mx-auto mt-10">
          <thead>
            <tr className="bg-brand-primary text-white">
              <th className="text-left px-5">Référence</th>
              <th className="text-right px-5">Montant</th>
              <th className="text-left px-5"></th>
            </tr>
          </thead>
          <tbody>
            {orders.map(order => <tr key={order.reference}> 
              <td className="text-md px-5">{order.reference}</td>
              <td className="text-right px-5">{order.totalPaid.toFixed(2)} €</td>
              <td className="text-left text-md pl-10 px-5">{order.state}</td>
            </tr>)}
          </tbody>
        </table>
      </div> }

    </div>

  </Page>
}

const Account = connector(aAccount)
export default Account
